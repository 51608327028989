import { default as _91id_93PiQELTf2B1Meta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexmX3anCtS3IMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93QvfP9l2vX8Meta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indextCKgpR3UfnMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorswvpd45XOO7Meta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsJ7rtnF25IzMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexlfxfpJKgHrMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as index4vBzXhmRTEMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboard8SoCAptE0tMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceVJhvfjkWiqMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/entrance.vue?macro=true";
import { default as indexUW4VYkUJU7Meta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/index.vue?macro=true";
import { default as login8fafCBAEVUMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/login.vue?macro=true";
import { default as indexk7MOrxw3tbMeta } from "/home/forge/merchants.smake.construction/releases/v1.27.1/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93PiQELTf2B1Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmX3anCtS3IMeta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexmX3anCtS3IMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93QvfP9l2vX8Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indextCKgpR3UfnMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorswvpd45XOO7Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsJ7rtnF25IzMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexlfxfpJKgHrMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index4vBzXhmRTEMeta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: index4vBzXhmRTEMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboard8SoCAptE0tMeta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboard8SoCAptE0tMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceVJhvfjkWiqMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: login8fafCBAEVUMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: login8fafCBAEVUMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.27.1/pages/index.vue").then(m => m.default || m)
  }
]